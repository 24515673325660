body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #f0f2f5;
}

.App {
  text-align: center;
  padding: 20px;
}

.App-header {
  background: linear-gradient(90deg, #1e3c72 0%, #2a5298 100%);
  color: white;
  padding: 20px 0;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.App-header h1 {
  margin: 0;
  font-size: 2.5rem;
}

section {
  margin: 20px auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
}

h2 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

h3 {
  color: #555;
  font-size: 1.25rem;
}

input[type="number"] {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background: #1e3c72;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background 0.3s ease;
}

button:hover {
  background: #2a5298;
}

label {
  font-size: 1rem;
  color: #333;
  display: block;
  margin-bottom: 10px;
}

div {
  margin-bottom: 20px;
}

.crypto-address {
  font-family: monospace;
  font-size: 1.2rem;
  color: #2a5298;
  background: #f0f2f5;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.copy-icon {
  margin-left: 10px;
  cursor: pointer;
  color: #2a5298;
  transition: color 0.3s ease;
}

.copy-icon:hover {
  color: #1e3c72;
}

.disclaimer-list {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.disclaimer-list li {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  section {
    padding: 10px;
  }

  input[type="number"] {
    width: 100%;
  }

  button {
    width: 100%;
  }
}
